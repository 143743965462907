<template>
  <div class="app-container excel">
    <div style="margin-bottom:10px">
      <el-button size="small" type="primary" @click="$router.push({name:'ChainCompanyList'})">企业查询</el-button>
      <el-button size="small" type="primary" @click="$router.push({name:'ChainGoodsList'})">商品查询</el-button>
    </div>
    <el-table :data="list" v-loading="listLoading" fit border style="width: 100%" :row-class-name="getRowClass"
      @expand-change="expandChange">
      <el-table-column label="上链信息动态统计表" align="center">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
              <el-table-column prop="region_name" align="center">
              </el-table-column>
              <el-table-column label="入驻平台企业总数" prop="rzpt" align="center">
              </el-table-column>
              <el-table-column label="入川首站企业数" prop="rcsz" align="center">
              </el-table-column>
              <el-table-column label="冷链运输车次数" prop="yscl" align="center">
              </el-table-column>
              <el-table-column label="进口冷链总批次数" prop="jkll" align="center">
              </el-table-column>
              <el-table-column
                label="收发货次数"
                prop="sfh"
                align="center"
              >
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="region_name" align="center"> </el-table-column>
        <el-table-column label="入驻平台企业总数" prop="rzpt" align="center">
        </el-table-column>
        <el-table-column label="入川首站企业数" prop="rcsz" align="center">
        </el-table-column>
        <el-table-column label="冷链运输车次数" prop="yscl" align="center">
        </el-table-column>
        <el-table-column label="进口冷链总批次数" prop="jkll" align="center">
        </el-table-column>
        <el-table-column
          label="收发货次数"
          prop="sfh"
          align="center"
        >
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
import axios from "@/util/api";
export default {
  data () {
    return {
      listLoading: false,
      innerListLoading: false,
      list: [],
    };
  },
  mounted () {
    this.initData();
  },
  methods: {
    initData () {
      this.listLoading = true;
      axios.get("pc/data-statistics/company-sl-list").then((response) => {
        let { data } = response;
        data.map((item) => {
          if (item.region_code.length > 2) {
            item.areaData = [];
            item.areaDataFlag = false;
          }
        });
        this.list = data;
        this.listLoading = false;
      });
    },
    expandChange (row, expandedRows) {
      if (expandedRows.length > 0) {
        this.innerListLoading = true;
        axios
          .get("pc/data-statistics/company-sl-list",{
            region_code: row.region_code,
          })
          .then((res) => {
            // 遍历当前页面表
            this.list.forEach((temp, index) => {
              // 找到当前点击的行，把动态获取到的数据赋值进去
              if (temp.region_code === row.region_code) {
                this.list[index].areaData = res.data;
              }
            });
            this.innerListLoading = false;
          });
      }
    },
    getRowClass (row, rowIndex) {
      let data = row.row.areaData;
      let res = [];
      if (data) {
        res.push("row-expand-has");
        return res;
      } else {
        res.push("row-expand-unhas");
        return res;
      }
    },
  },
};
</script>

<style>
.row-expand-unhas .el-table__expand-column {
  pointer-events: none;
}
.row-expand-unhas .el-table__expand-column .el-icon {
  visibility: hidden;
}
.excel .el-table__expanded-cell {
  background-color: rgb(217, 236, 255);
}
</style>